import { getWidgetResolution, getWidgetSerieType } from '../domain';
import {
	readChartDrawingItems,
	readChartIndicatorItems,
	readChartInterval,
	readWidget,
	saveChartCandleSize,
	saveChartDrawingItems,
	saveChartIndicatorItems,
	saveChartInterval,
	saveChartType,
} from '../../infrastructure/store';
import type {
	ChartCandleSize,
	ChartInterval,
	ChartType,
} from '../../types';
import { useUpdateChartSeriesRates } from './updateChartSeriesRates';

export const useChangeChartCandleSize = () => {
	return (candleSize: ChartCandleSize) => {
		saveChartCandleSize(candleSize);
	};
};

export const useChangeChartType = () => {
	const updateChartSeriesRates = useUpdateChartSeriesRates();
	const chartTypeCookie = useCookie<ChartType>('chartType');
	return (chartType: ChartType) => {
		const widget = readWidget();
		if (!widget) {
			return;
		}

		const seriesType = getWidgetSerieType(chartType);

		widget.activeChart().setChartType(seriesType);
		saveChartType(chartType);
		chartTypeCookie.value = chartType;
		updateChartSeriesRates();
	};
};

export const useChangeChartInterval = () => {
	const chartIntervalCookie = useCookie<ChartInterval>('chartInterval');

	return (chartInterval: ChartInterval) => {
		const widget = readWidget();
		if (!widget) {
			return;
		}

		const oldInterval = readChartInterval();
		if (oldInterval === chartInterval) {
			return;
		}

		const resolution = getWidgetResolution(chartInterval);

		widget.activeChart().setResolution(resolution);

		saveChartInterval(chartInterval);
		chartIntervalCookie.value = chartInterval;
	};
};

export const useChartChangeShapeById = () => {
	return (button: any, type: string) => {
		const widget = readWidget();
		if (type === 'visibility') {
			widget?.activeChart().getShapeById(button.id).setVisible(button.isVisible);
		}
		if (type === 'delete') {
			widget?.activeChart().removeEntity(button.id);
			useRemoveChartDrawingItem(button.id);
		}
	};
};

export const useAddChartDrawingItem = () => {
	return (drawing: any) => {
		const widget = readWidget();
		if (!widget) return;

		const drawingEventSubscription = (id: string) => {
			const newDrawing = { ...drawing, id };
			saveChartDrawingItems(readChartDrawingItems().concat(newDrawing));

			widget.unsubscribe('drawing_event', drawingEventSubscription);
		};

		widget.subscribe('drawing_event', drawingEventSubscription);
		widget.selectLineTool(drawing.value);
	};
};

export const useRemoveChartDrawingItem = (id: string) => {
	const currentItems = readChartDrawingItems();
	const updatedItems = currentItems.filter(item => item.id !== id);
	saveChartDrawingItems(updatedItems);
};

export const useAddChartIndicatorItem = () => {
	return (indicator: any) => {
		const widget = readWidget();
		const entity = widget?.activeChart().createStudy(indicator.value, false, false);

		entity?.then((id) => {
			const newIndicator = {
				value: indicator.value,
				text: indicator.text,
				id,
			};
			saveChartIndicatorItems(readChartIndicatorItems().concat(newIndicator));
		});
	};
};
export const useRemoveIndicatorById = () => {
	return (id: string) => {
		const widget = readWidget();
		widget?.activeChart().removeEntity(id);
		useRemoveChartIndicatorItem(id);
	};
};
export const useRemoveChartIndicatorItem = (id: string) => {
	const currentIndicators = readChartIndicatorItems();
	const updatedIndicators = currentIndicators.filter(item => item.id !== id);
	saveChartIndicatorItems(updatedIndicators);
};
